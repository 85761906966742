<template>
  <div class="fragment">
    <ConsolidationCreateContent
        :consolidation="consolidation"
        :destinationData="destinationData"
        :deliveryServices="deliveryServices"
        :loadingFedexWarehouses="loadingFedexWarehouses"
        :showPromoFields="showPromoFields"
        @prepareSaveConsolidation="prepareSaveConsolidation"
        @userChanged="initConsolidationCreate"
        @createLabel="createLabel"
    />

    <AuthorizePaymentPopup
        v-if="authorizePaymentPopup"
        :countMoney="authorizePaySum"
        :authorizePayData="authorizePayData"
        :authorizeError="authorizeError"
        :serverError="serverError"
        :changeCloseToSaveBtn="true"
        @close="()=>{$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
        @submitForm="submitAuthorizeInvoicePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :invoicePayId="authorizeInvoiceId"
        :changeCloseToSaveBtn="true"
        @submitForm="afterPay"
        @close="()=>{$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
    />

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="() => {$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />
  </div>
</template>

<script>
  import ConsolidationCreateContent from "./ConsolidationCreateUser/ConsolidationCreateContent";
  import {Consolidation} from "../../models/Consolidation";
  import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
  import {
    CONSOLIDATION_TYPES,
    CONSOLIDATION_TYPES_ARRAY,
    ORDERS_CONSOLIDATION_TYPE,
    CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY,
    CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY, ORDERS_CONSOLIDATION_TYPE_FROM_URL, CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY
  } from "../../../../../staticData/staticVariables";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import AuthorizePaymentPopup from "../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import BraintreePaymentPopup from "../../../../coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../mixins/dashboardMixins/paymentBlockFunc";

  export default {
    name: "ConsolidationCreate",

    components: {
      ThankYouPopup,
      BraintreePaymentPopup,
      AuthorizePaymentPopup,
      ConsolidationCreateContent,
    },

    mixins: [
      consolidationMixin,
      proformMixins,
      queryFilterMixin,
      paymentBlockFunc
    ],

    watch: {
      loadUserRoleAndAuthorizedData: function (val) {
        if (val) {
          this.getUserData()
          this.initConsolidationCreate()
        }
      },
    },

    data() {
      return {
        consolidation: new Consolidation(),
        destinationData: [],
        destinationWarehouseData: [],
        deliveryServices: [],
        loadingFedexWarehouses: false,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,
        showPromoFields: false,

        //set in consolidationMixin on connsolidation create
        createdConsoId: -1,
      }
    },

    created() {
      this.initShippingCompanies()

      this.initializeProductProformArray(this.consolidation.data.proformOrders).then(() => {
        this.initializeProductProformArray(this.consolidation.data.proformProducts, 0)
        /**
         * initial first product in array
         */
        this.consolidation.addProductForCells()
        this.initializeProductProformArray(this.consolidation.data.productsForCellArray, 0)
      })


      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
        this.initConsolidationCreate()
      }
    },

    methods: {
      async initConsolidationCreate() {
        if(!this.consolidation.data.User.user?.id) {
          return
        }

        this.responseData = await this.$store.dispatch('getConsolidationCreate').then(response => {
          return this.getRespData(response)
        }).catch(error => this.createErrorLog(error))

        // Show promo codes
        !this.responseData?.promocodes_available ? this.showPromoFields = false : this.showPromoFields = true

        let currentDeliveryMethod = this._.find(ORDERS_CONSOLIDATION_TYPE_FROM_URL, {fromUrl: this.$route.params.type})?.typeObj
        console.log('current delivery method ',currentDeliveryMethod);

        /**
         * get destination data only for our delivery service
         */
        this.destinationData = this.responseData.destinations.filter((item) => {
          return item.delivery_service_id === currentDeliveryMethod.delivery_service_id
        })

        //temp fix, delete later
        if (currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dhl') {
          this.destinationData = this.destinationData.filter((item) => {
            return item.api !== 1
          })
        }

        /**
         *  get destination data only for from your city type
         */
        if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY) {
          this.destinationData = this.responseData.destinations.filter((item) => {
            return CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY.find(findItem => {

              // fix, remove DHL_USA type from order creation
              if(CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.id &&
                  ((!this.isUseAlternativeContact && this.isUkrainian) || this.isUserAlternativeContactUkraine)) {
                return false
              }


              if(this.isLatvian && (findItem.id === CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id || findItem.id === CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id)) {
                return false
              }

              return +findItem.id === +item.id
            })

          })

          this.destinationWarehouseData = this.responseData.destinationWarehouses
          //set default destination warehouse
          if (this.destinationWarehouseData?.length > 0) {
            this.consolidation.setRecipientWarehouse(this.destinationWarehouseData[0])
          }
        }

        console.log(this.destinationData);

        if (this.$route.query.initStorage) {
          this.consolidation.setOrderWarehouse({id: +this.$route.query.initStorage})
        }

        //set the first element to model data automatically on opening create page
        if (this.destinationData.length > 0) {
          this.setFirstDestinationAndWarehouse(currentDeliveryMethod)
        }
      },

      setFirstDestinationAndWarehouse(currentDeliveryMethod) {
        // If Consolidation Fedex || DPD || EU_PRODUCT || DHL => set warehouses from fetchWarehousesFromConsolidation
        if(
            currentDeliveryMethod.name === 'fedex' ||
            currentDeliveryMethod.name === 'dpd' ||
            currentDeliveryMethod.name === 'eu_product' ||
            currentDeliveryMethod.name === 'dhl')
        {
          let myQuery = this.createQueryBuilderObject()
          let userContacts = this.getUserContactDataFromUser(this.consolidation.data.User.user)

          if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
            userContacts = this.getUserAlternativeContact(this.consolidation.data.User.user)
          }

          let countryGroupIds = userContacts?.country_group?.ids

          myQuery.params({
            delivery_service_id: currentDeliveryMethod.delivery_service_id,
            country_group_id: countryGroupIds && countryGroupIds.length > 0 ? countryGroupIds.join(',') : ''
          })

          let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

          this.$store.dispatch('fetchWarehousesFromConsolidation', url).then(response => {
            this.consolidation.data.fedexWarehouses = this.getRespData(response)
            //ВРЕМЕННЫЙ ФИКС, СПРОСИТЬ У БЭКА ПОЧЕМУ АПИ ФЛАЖОК ВКЛЮЧЕН (Вова)
            if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT) {
              this.destinationData[0].api = 0
            }
            this.consolidation.setConsolidationDestination(this.destinationData[0])
            this.consolidation.setWarehouse(this.consolidation.data.fedexWarehouses.length > 0 ? this.consolidation.data.fedexWarehouses[0] : '')
            this.loadingFedexWarehouses = true
          }).catch(error => this.createErrorLog(error))

        } else {
          this.consolidation.setConsolidationDestination(this.destinationData[0])
          if (!this.isFromYourCity) {
            this.consolidation.setWarehouse(this.destinationData[0]?.warehouses.length ? this.destinationData[0]?.warehouses[0] : '')
          }
          this.loadingFedexWarehouses = true
        }
      },

      _OLD_initConsolidationCreate() {
        if(!this.consolidation.data.User.user?.id) {
          return
        }

        this.$store.dispatch('getConsolidationCreate').then(response => {

          let realParamsType = this.$route.params.type

          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FEDEX) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.name
          }
          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DHL_USA) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.name
          }
          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DPD) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.name
          }


          let currentDeliveryMethod = null
          this.responseData = this.getRespData(response)

          if (!this.responseData?.promocodes_available) {
            this.showPromoFields = false
          }
          else {
            this.showPromoFields = true
          }

          if (this.$route.params.type !== ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY) {
            currentDeliveryMethod = this._.find(CONSOLIDATION_TYPES_ARRAY, {name: realParamsType})
            console.log('current delivery method ',currentDeliveryMethod);


            //get destination data only for our delivery service
            this.destinationData = this.responseData.destinations.filter((item) => {
              return item.delivery_service_id === currentDeliveryMethod.delivery_service_id
            })

            //temp fix, delete later
            if (currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dhl') {
              this.destinationData = this.destinationData.filter((item) => {
                return item.api !== 1
              })
            }
          }
          else {
            currentDeliveryMethod =  this._.find(CONSOLIDATION_TYPES_ARRAY, {name: CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.name})

            //get destination data only for from your city type
            this.destinationData = this.responseData.destinations.filter((item) => {
              return CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY.find(findItem => {

                // fix, remove DHL_USA type from order creation
                if(CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.id &&
                    ((!this.isUseAlternativeContact && this.isUkrainian) || this.isUserAlternativeContactUkraine)) {
                  return false
                }

                return +findItem.id === +item.id
              })

            })

            this.destinationWarehouseData = this.responseData.destinationWarehouses
            //set default destination warehouse
            if (this.destinationWarehouseData?.length > 0) {
              this.consolidation.setRecipientWarehouse(this.destinationWarehouseData[0])
            }
          }

          console.log(this.destinationData);

          if (this.$route.query.initStorage) {
            this.consolidation.setOrderWarehouse({id: +this.$route.query.initStorage})
          }

          //set the first element to model data automatically on opening create page
          if (this.destinationData.length > 0) {

            // If Consolidation Fedex => set warehouses from fetchWarehousesFromConsolidation
            if(currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dpd' || currentDeliveryMethod.name === 'eu_product' || currentDeliveryMethod.name === 'dhl') {
              let myQuery = this.createQueryBuilderObject()
              let userContacts = this.getUserContactDataFromUser(this.consolidation.data.User.user)

              if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
                userContacts = this.getUserAlternativeContact(this.consolidation.data.User.user)
              }

              let countryGroupIds = userContacts?.country_group?.ids

              myQuery.params({
                delivery_service_id: currentDeliveryMethod.delivery_service_id,
                country_group_id: countryGroupIds && countryGroupIds.length > 0 ? countryGroupIds.join(',') : ''
              })

              let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

              this.$store.dispatch('fetchWarehousesFromConsolidation', url).then(response => {
                this.consolidation.data.fedexWarehouses = this.getRespData(response)
                //ВРЕМЕННЫЙ ФИКС, СПРОСИТЬ У БЭКА ПОЧЕМУ АПИ ФЛАЖОК ВКЛЮЧЕН (Вова)
                if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT) {
                  this.destinationData[0].api = 0
                }
                this.consolidation.setConsolidationDestination(this.destinationData[0])
                this.consolidation.setWarehouse(this.consolidation.data.fedexWarehouses.length > 0 ? this.consolidation.data.fedexWarehouses[0] : '')
                this.loadingFedexWarehouses = true
              }).catch(error => this.createErrorLog(error))

            } else {
              this.consolidation.setConsolidationDestination(this.destinationData[0])
              if (!this.isFromYourCity) {
                this.consolidation.setWarehouse(this.destinationData[0]?.warehouses.length ? this.destinationData[0]?.warehouses[0] : '')
              }
              this.loadingFedexWarehouses = true
            }


          }
        }).catch(error => this.createErrorLog(error))
      },

      initShippingCompanies() {
        // const query = new this.Query();
        // let url = '?';
        // let myQuery = query
        //     .for('posts')
        // let allowedDeliveryServicesIds = CONSOLIDATION_DELIVERY_SERVICES_ARRAY.map(item => {
        //   return item.id
        // }).join(',')
        //
        // myQuery.where('DeliveryServiceIds', allowedDeliveryServicesIds)
        //
        // url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        let userCountry = this.getUserContactDataFromUser(this.getCurrentUser)?.country?.id

        this.$store.dispatch('getShippingCompanyByCountryId', userCountry).then(response => {
          this.deliveryServices = this.getRespData(response)

          if (this.deliveryServices.length === 1 && !this.isFromAPI) {
            this.consolidation.setDeliveryService(this.deliveryServices[0])
          }
        })
      },

      getUserData() {
        if (this.isCurrentUserRoleUser) {
          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.consolidation.data.User.setUser(userData)

          if(this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DHL_USA && !this.isUkrainian) {
            return this.$router.push(this.$store.getters.GET_PATHS.notFound)
          }
        }
      },

      createLabel() {
        this.prepareSaveConsolidation({draft: false, edit: false, createLabel: true})
      },

      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.createdConsoId)
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },

      afterPay() {
        this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.createdConsoId)
        this.braintreePaymentPopup = false

        this.changeThankYouPopupPopup(true)
      },
    }
  }
</script>

<style scoped>

</style>

